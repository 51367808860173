import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
  'zh-TW': {
    translation: require('../../locales/zh-TW/translation.json'),
    homePage: require('../../locales/zh-TW/homePage.json'),
    contactPage: require('../../locales/zh-TW/contactPage.json'),
    businessPage: require('../../locales/zh-TW/businessPage.json'),
    productPage: require('../../locales/zh-TW/productPage.json'),
    aboutPage: require('../../locales/zh-TW/aboutPage.json'),
  },
  en: {
    translation: require('../../locales/en/translation.json'),
    homePage: require('../../locales/en/homePage.json'),
    contactPage: require('../../locales/en/contactPage.json'),
    businessPage: require('../../locales/en/businessPage.json'),
    productPage: require('../../locales/en/productPage.json'),
    aboutPage: require('../../locales/en/aboutPage.json'),
  },
  fr: {
    translation: require('../../locales/fr/translation.json'),
    homePage: require('../../locales/fr/homePage.json'),
    contactPage: require('../../locales/fr/contactPage.json'),
    businessPage: require('../../locales/fr/businessPage.json'),
    productPage: require('../../locales/fr/productPage.json'),
    aboutPage: require('../../locales/fr/aboutPage.json'),
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    localeJsonSourceName: 'locale',
    defaultNS: 'translation',
    ns: ['translation', 'aboutPage', 'homePage', 'businessPage', 'contactPage', 'productPage'],
    interpolation: {
      escapeValue: false,
    },
    redirect: true,
    debug: true,
    //siteUrl: "https://www.afriocean.com/",
    siteUrl: 'http://localhost:8000/',
    nsSeparator: false,
    prefixDefault: false,
    useLangKeyLayout: false,
  });

export default i18n;
