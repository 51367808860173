import React from "react";
import { I18nextProvider } from "react-i18next";
import i18next from "./src/i18n/i18next";
import { PageContextProvider } from "./src/components/Common/PageContext";

export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18next}>{element}</I18nextProvider>;
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <PageContextProvider value={props.pageContext}>
      {element}
    </PageContextProvider>
  );
};
