exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-factory-jsx": () => import("./../../../src/pages/about/factory.jsx" /* webpackChunkName: "component---src-pages-about-factory-jsx" */),
  "component---src-pages-about-history-jsx": () => import("./../../../src/pages/about/history.jsx" /* webpackChunkName: "component---src-pages-about-history-jsx" */),
  "component---src-pages-about-quality-jsx": () => import("./../../../src/pages/about/quality.jsx" /* webpackChunkName: "component---src-pages-about-quality-jsx" */),
  "component---src-pages-about-vision-jsx": () => import("./../../../src/pages/about/vision.jsx" /* webpackChunkName: "component---src-pages-about-vision-jsx" */),
  "component---src-pages-business-elaborated-product-jsx": () => import("./../../../src/pages/business/elaborated-product.jsx" /* webpackChunkName: "component---src-pages-business-elaborated-product-jsx" */),
  "component---src-pages-business-frozen-product-jsx": () => import("./../../../src/pages/business/frozen-product.jsx" /* webpackChunkName: "component---src-pages-business-frozen-product-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-imprint-jsx": () => import("./../../../src/pages/imprint.jsx" /* webpackChunkName: "component---src-pages-imprint-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-product-fish-jsx": () => import("./../../../src/pages/product/fish.jsx" /* webpackChunkName: "component---src-pages-product-fish-jsx" */),
  "component---src-pages-product-seafood-jsx": () => import("./../../../src/pages/product/seafood.jsx" /* webpackChunkName: "component---src-pages-product-seafood-jsx" */),
  "component---src-templates-product-item-page-jsx": () => import("./../../../src/templates/ProductItemPage.jsx" /* webpackChunkName: "component---src-templates-product-item-page-jsx" */)
}

