import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/all.scss';
import 'aos/dist/aos.css';
import 'animate.css';

const PageContext = React.createContext({});

export const PageContextProvider = (pageContext) => {
  const { children, value } = pageContext;
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(value.lang);
  }, [i18n, value]);

  return <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>;
};

export const usePageContext = () => useContext(PageContext);
